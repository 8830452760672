<template>
  <div class="statistical">
    <div class="statistical_top">
      <div class="statistical_top_flex">
        <div class="border_left"></div>
        <div class="top_name">
          <span>综合面板</span>
        </div>
      </div>
      <div class="statistical_top_center">
        <div class="statistical_top_center_item">
          <div class="name_size">
            <span>认领单位数</span>
          </div>
          <div class="center_flex">
            <img src="../../../assets/image/zf1.png" alt="" />
            <div>
              <span>{{ headinfo.unitNum }}</span>
            </div>
          </div>
        </div>
        <div class="statistical_top_center_item">
          <div class="name_size">
            <span>客户经理数</span>
          </div>
          <div class="center_flex">
            <img src="../../../assets/image/zf2.png" alt="" />
            <div>
              <span>{{ headinfo.customerNum }}</span>
            </div>
          </div>
        </div>
        <div class="statistical_top_center_item">
          <div class="name_size">
            <span>走访企业数</span>
          </div>
          <div class="center_flex">
            <img src="../../../assets/image/zf4.png" alt="" />
            <div>
              <span>{{ headinfo.visitCompanyNum }}</span>
            </div>
          </div>
        </div>
        <div class="statistical_top_center_item">
          <div class="name_size">
            <span>走访次数</span>
          </div>
          <div class="center_flex">
            <img src="../../../assets/image/zf3.png" alt="" />
            <div>
              <span>{{ headinfo.visitNum }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistical_center">
      <el-form ref="form" :model="queryInfo" label-width="100px">
        <el-form-item label="诊断工具：" v-if="iconindex == 0">
          <div class="radio_group">
            <el-radio-group v-model="queryInfo.questionnaireId" @change="changVal">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button v-for="(item, index) in questList" :key="index" :label="item.id">{{
                item.questionnaireName
              }}</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="认领单位：">
          <el-radio-group v-model="queryInfo.unitId" @change="unitChange">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button v-for="(item, index) in datalist" :key="index" :label="item.id">{{
              item.unit
            }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="走访达标：">
          <el-radio-group v-model="queryInfo.reachStandard">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button :label="1">达标</el-radio-button>
            <el-radio-button :label="0">未达标</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="statistical_bottom">
      <div class="statistical_top_flex">
        <div class="border_left"></div>
        <div class="top_name">
          <span>走访统计</span>
        </div>
        <div class="icon_flex">
          <div
            @click="iconClick(index)"
            :class="iconindex == index ? 'icon_flex_item icon_back' : 'icon_flex_item'"
            v-for="(item, index) in tableList"
            :key="index"
          >
            <img v-if="iconindex != index" :src="item.img1" alt="" />
            <img v-else :src="item.img2" alt="" />

            <span style="margin-left: 5px">{{ item.name }}</span>
          </div>
        </div>
        <div class="tab_flex">
          <div
            @click="tabitem(index)"
            :class="tabindex == index ? 'tab_flex_item back' : 'tab_flex_item'"
            v-for="(item, index) in tabList"
            :key="index"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div v-show="tabindex == 0">
          <el-date-picker
            v-model="queryInfo.time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div v-show="tabindex == 1">
          <el-date-picker
            v-model="queryInfo.time"
            type="week"
            value-format="yyyy-MM-dd"
            format="yyyy 第 WW 周"
            placeholder="选择周"
          >
          </el-date-picker>
        </div>
        <div v-show="tabindex == 2">
          <el-date-picker
            value-format="yyyy-MM"
            v-model="queryInfo.time"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
        </div>
        <div v-show="tabindex == 3">
          <el-date-picker
            value-format="yyyy"
            v-model="queryInfo.time"
            type="year"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
        <div class="buttom_left" v-if="iconindex == 0">
          <el-button plain @click="getexport">批量导出</el-button>
        </div>
        <div v-if="iconindex == 1" class="line_right">
          <div class="line_right_item">
            <img src="../../../assets/image/e1.png" alt="" />
            <div>
              <span>客户经理人数</span>
            </div>
          </div>
          <div class="line_right_item">
            <img src="../../../assets/image/e2.png" alt="" />
            <div>
              <span>走访次数</span>
            </div>
          </div>
          <div class="line_right_item">
            <img src="../../../assets/image/e3.png" alt="" />
            <div>
              <span>达标次数</span>
            </div>
          </div>
          <div class="line_right_item">
            <img style="width: 28px; height: 28px" src="../../../assets/image/e4.png" alt="" />
            <div>
              <span>（注:走访达标人均2次/天）</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="iconindex == 0">
        <tablelist
          :type="queryInfo.type"
          :reachStandard="queryInfo.reachStandard"
          :questionnaireId="queryInfo.questionnaireId"
          :unitId="queryInfo.unitId"
          @getids="getids"
          :time="queryInfo.time"
        ></tablelist>
      </div>
      <div v-if="iconindex == 1">
        <linetable
          :type="queryInfo.type"
          :reachStandard="queryInfo.reachStandard"
          :unitId="queryInfo.unitId"
          :time="queryInfo.time"
          :unit="unit"
        ></linetable>
      </div>
    </div>
  </div>
</template>
  
  <script>
import linetable from './components/linetable.vue'
import tablelist from './components/tablelist.vue'
import {
  getOneLevelUnit,
  getVisitRecordHeadStatistics,
  selectQuestionnaire,
  selectVisitRecord,
  voneexport
} from '@/api/visiting.js'

const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  time: '', //头部；时间
  type: 1, //1=日，2=周，3=月，4=年
  unitId: '',
  reachStandard: '',
  questionnaireId: ''
})
export default {
  name: 'statistical',
  components: { linetable, tablelist },
  data() {
    return {
      value1: '',
      tabindex: 0,
      iconindex: 0,
      form: {
        resource: ''
      },
      questionnaireName: '', //导出名
      ids: [],
      questList: [], //工具list
      unit: '',
      queryInfo: { ...defaultQueryInfo },
      tableList: [
        {
          name: '列表',
          img1: require('../../../assets/image/l1.png'),
          img2: require('../../../assets/image/l2.png')
        },
        {
          name: '图表',
          img1: require('../../../assets/image/l3.png'),
          img2: require('../../../assets/image/l4.png')
        }
      ],
      tabList: [
        {
          name: '日',
          value: 1
        },
        {
          name: '周',
          value: 2
        },
        {
          name: '月',
          value: 3
        },
        {
          name: '年',
          value: 4
        }
      ],
      headinfo: {}, //头部数据
      datalist: [],
      total: 0
    }
  },
  computed: {},
  created() {
    this.getOneLevelUnit()
    this.getVisitRecordHeadStatistics()
    this.selectQuestionnaire()
  },
  mounted() {
    this.queryInfo.time = this.getTodayDate()
  },
  destroyed() {},
  methods: {
    async selectQuestionnaire() {
      const res = await selectQuestionnaire()
      if (res.resultCode == 200) {
        this.questList = res.data
      }
    },
    //获取id
    getids(val) {
      this.ids = val
    },
    changVal(val) {
      this.questList.forEach((el) => {
        console.log(el)

        if (val == el.id) {
          this.questionnaireName = el.questionnaireName
        }
      })
    },
    getexport() {
      if (this.queryInfo.type == 2) {
        let time = this.getTodayDate()
        this.queryInfo.weekNum = this.getWeekNumber(new Date(time))
      }
      if (this.queryInfo.unitId && this.queryInfo.type != 1) {
        this.getexports(true)
      } else {
        this.getexports()
      }
    },
     // 计算第几周
     getWeekNumber(date) {
      // 获取一周的第一天（周日）的日期
      const firstDay =
        date.getDay() === 0
          ? date
          : new Date(date.getTime() - ((date.getDay() || 7) - 1) * 86400000)

      // 获取一年第一周的第一天（周日）的日期
      const firstWeekFirstDay = new Date(firstDay.getFullYear(), 0, 1)

      // 如果第一周的第一天不是周日，则将其调整为周日
      if (firstWeekFirstDay.getDay() !== 0) {
        firstWeekFirstDay.setDate(firstWeekFirstDay.getDate() - firstWeekFirstDay.getDay() + 1)
      }

      // 计算偏移量
      const offset = Math.floor((firstDay.getTime() - firstWeekFirstDay.getTime()) / (7 * 86400000))

      // 返回周数（可能为0，表示前一年的最后一周）
      
      return offset
    },
       
    async getexports(index) {
      this.loading = true
      let nowtime=''
      if (this.queryInfo.type == 2) {
        const today = new Date(this.queryInfo.time)
        const year = today.getFullYear()
        let num=this.getWeekNumber(new Date(this.queryInfo.time))
        if(num==52){
          num=1
      }else{
        num=num+1
      }
      
        nowtime =year+'第'+num +'周'
      } else if (this.queryInfo.type == 3) {
        const today = new Date(this.queryInfo.time)
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        nowtime = `${year}-${month}`
      } else if (this.queryInfo.type == 4) {
        const today = new Date(this.queryInfo.time)
        const year = today.getFullYear()

        nowtime = `${year}`
      } else {
        nowtime = this.queryInfo.time
      }
      let query = this.queryInfo

      if (this.ids.length) {
        query['ids'] = this.ids
      }
      delete query.pageNum
      delete query.pageSize
      const fn = index ? selectVisitRecord : voneexport
      const res = await fn(query)
      let questionnaireName = this.questionnaireName
      if (res) {
        this.loading = false
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        
        a.download = `${questionnaireName ? questionnaireName : '走访记录'}${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.warning('下载失败!')
      }
    },
    //获取当前年月日
    getTodayDate() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1 // 月份是从0开始的，所以需要+1
      const day = today.getDate()
      return `${year}-${month}-${day}`
    },
    async getOneLevelUnit() {
      const res = await getOneLevelUnit()
      if (res.resultCode == 200) {
        this.datalist = res.data
        this.datalist.forEach(el=>{
          el.id=String(el.id)
        
        })  
       
      }
    },
    async getVisitRecordHeadStatistics() {
      const res = await getVisitRecordHeadStatistics()
      if (res.resultCode == 200) {
        this.headinfo = res.data
      }
    },
    unitChange(val) {
     
      this.datalist.forEach((el) => {
        if (el.id == val) {
          this.unit = el.unit
        }
      })
    },
    tabitem(index) {
      this.tabindex = index
      this.queryInfo.type = this.tabList[index].value
      this.queryInfo.time = this.getTodayDate()
      //   const today =  new Date(this.getTodayDate())
      //   if(this.queryInfo.type ==1){
      //     this.queryInfo.time=this.getTodayDate()
      //   }else if(this.queryInfo.type==2){
      //     this.queryInfo.time=this.getTodayDate()
      //   }else if(this.queryInfo.type==3){
      //     const year = today.getFullYear()
      //     const month = today.getMonth() + 1
      //     this.queryInfo.time = `${year}-${month}`
      //   }else if(this.queryInfo.type==4){
      //     const year = today.getFullYear()

      //     this.queryInfo.time = `${year}`
      //   }
      //   console.log(this.queryInfo.time);
    },

    iconClick(index) {
      this.iconindex = index
    }
  }
}
</script>
  
  <style lang="less" scoped>
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .el-radio-button .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 7px 12px;
  font-size: 14px;
  border-radius: 0;
}
.statistical {
  width: 100%;
  height: calc(100vh - 100px);
  .statistical_top {
    width: 100%;
    background: #fff;
    padding: 20px 0px;
    box-shadow: 0px 4px 4px 0px #c1c1c126;
    border-radius: 4px;
    .statistical_top_flex {
      display: flex;
      align-items: center;
      .border_left {
        width: 3px;
        height: 20px;
        background: #448aff;
      }
      .top_name {
        font-size: 16px;
        font-weight: bold;
        margin: 0px 15px;
      }
      .tab_flex {
        display: flex;
        align-items: center;
        border-radius: 2px;
        margin-right: 15px;
        .tab_flex_item {
          height: 37px;
          border-radius: 1px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          border: 1px solid #d7dbec;
          cursor: pointer;
        }
        .back {
          color: #448aff;
          border: 1px solid #448aff;
        }
      }
    }
    .statistical_top_center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      padding: 0px 80px;
      .statistical_top_center_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .name_size{
          font-size: 17px;
        }
        .center_flex {
          margin-top: 10px;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          img {
            width: 56px;
            height: 56px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .border_left {
        width: 3px;
        height: 20px;
        background: #448aff;
      }
      .top_name {
        font-size: 18px;
        font-weight: bold;
        margin: 0px 15px;
      }
  .statistical_center {
    padding: 20px;
    background: #fff;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #c1c1c126;
    border-radius: 4px;
  }

  .statistical_bottom {
    padding: 20px 0px;
    background: #fff;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #c1c1c126;
    min-height: calc(100vh - 460px);
    border-radius: 4px;
    .statistical_top_flex {
      display: flex;
      align-items: center;
      .buttom_left {
        margin-left: 15px;
      }
     
      .tab_flex {
        display: flex;
        align-items: center;
        border-radius: 2px;
        margin-right: 15px;
        .tab_flex_item {
          height: 37px;
          border-radius: 1px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          border: 1px solid #d7dbec;
          cursor: pointer;
        }
        .back {
          color: #448aff;
          border: 1px solid #448aff;
        }
      }
      .line_right {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .line_right_item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          img {
            height: 30px;
            width: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .icon_flex {
      display: flex;
      align-items: center;
      margin: 0px 30px;
      .icon_flex_item {
        color: #448aff;
        border: 1px solid #448aff;
        padding: 0px 10px;
        height: 37px;

        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .icon_back {
        background: #448aff;
        color: #fff;
      }
    }
  }
}
</style>
  